import '../App.css';

import warning_tiny from '../assets/warning-tiny.png';
import '../web-fonts/font.css';
import deyu_id from '../assets/deyu_id.png';
import deyu_message from '../assets/deyu_message.png';
import ImageMapper from 'react-img-mapper';

function P2() {
  return (
    <div className="App">
      <div className="packages">
        {/* <img src={deyu_id} usemap="#image-map" width={800} /> */}
        <img id="deyu-id" src={deyu_id} width={800} draggable={false} />
        <img id="deyu-id-mob" src={deyu_id} width={'100%'} draggable={false} />
        <img
          id="deyu-message"
          src={deyu_message}
          width={'100%'}
          draggable={false}
        />

        {/* <map name="image-map">
          <area
            target="_blank"
            alt="EUPHONIA"
            title="EUPHONIA"
            href="thelovebetween.us/euphonia"
            coords="64,293,190,161,249,197,258,181,288,196,311,183,320,147,373,99,410,68,493,31,540,17,599,11,667,20,709,44,739,89,755,139,756,191,753,215,756,226,791,278,791,296,783,303,756,320,753,337,726,369,693,404,667,451,642,497,614,536,585,559,549,581,499,586,442,584,369,574,329,552,302,514,287,495,286,460,317,417,363,381,398,356,455,328,496,311,536,283,557,263,569,232,559,210,536,191,495,173,450,170,410,179,363,193,333,201,322,197,334,183,348,187,390,174,433,167,466,163,520,169,543,183,572,211,579,258,573,285,560,309,493,354,342,449,323,507,325,524,393,544,434,550,494,548,545,539,573,524,595,497,628,446,671,381,706,334,719,302,758,282,772,274,758,233,739,199,746,160,736,107,720,75,692,45,654,24,596,17,515,28,472,48,419,77,392,94,367,123,325,167,337,182,316,200,308,208,322,233,322,242,316,248,302,246,318,269,318,279,300,294,292,297,272,377,283,387,284,394,279,399,294,416,296,423,244,485,220,470,211,475,201,469,156,510,134,498,65,568,20,538,33,526,108,451,38,385,99,321"
            shape="poly"
          />
        </map> */}
      </div>
    </div>
  );
}

export default P2;
