import './App.css';
import Draggable from 'react-draggable';
import p1 from './assets/p1/row-1-column-1.png';
import p2 from './assets/p1/row-1-column-2.png';
import p3 from './assets/p1/row-1-column-3.png';
import p4 from './assets/p1/row-2-column-1.png';
import p5 from './assets/p1/row-2-column-2.png';
import p6 from './assets/p1/row-2-column-3.png';
import p7 from './assets/p1/row-3-column-1.png';
import p8 from './assets/p1/row-3-column-2.png';
import p9 from './assets/p1/row-3-column-3.png';
import warning_tiny from './assets/warning-tiny.png';
import './web-fonts/font.css';

function App() {
  return (
    <div className="App">
      <div className="warning">
        <div className="warning-header">WARNING!</div>
        <img src={warning_tiny} />
        <div>
          <b>neural-sea</b> receptions are best accessed on a large-screen
          device.
        </div>
      </div>
      <div className="packages">
        <div className="p1">
          <div className="p1-text">
            <p>{'> '}Accessing neural-sea…</p> <p>{'> '}Neural-sea accessed.</p>{' '}
            <p>{'> '}Welcome to The Colony.</p>{' '}
            <p>
              {'> '}
              <b>ERROR!</b>
            </p>{' '}
            <p>
              {'> '}The island you are attempting to connect to is not part of
              The Colony.
            </p>{' '}
            <p>
              {'> '}The Colony hopes for neural-sea to eventually be accessed by
              every region of the globe. However, as a privileged member, we are
              happy to extend{' '}
              <span className="p1-link">a unique subscription code</span> (with
              a special 5% discount off their first year!) if you would like to
              invite a close one before neural-sea becomes accessible in their
              region. The code will expire in-
            </p>{' '}
            <p>
              {'> '}
              <b>DECLINE.</b>
            </p>{' '}
            <p>
              {'> '}* The Colony is not liable for any personal communications
              on islands outside of official neural-sea territory.
            </p>{' '}
            <p>
              {'> '}
              <span className="p1-link">Return to safety.</span>
            </p>{' '}
            <p>
              {'> '}
              <b>OVERRIDE.</b>
            </p>{' '}
            <p>{'> '}Establishing private passage…</p>{' '}
            <p>
              {'> '}Generating neural-sea island for:{' '}
              <b>
                <span className="p1-name">[SAYANG]</span>
              </b>
              ,{' '}
              <b>
                <span className="p1-name">[DEYU]</span>
              </b>
              ...
            </p>{' '}
            <p>{'> '}Encrypting personal vessel…</p> <p>{'> '}Connecting…</p>{' '}
            <p>
              {'> '}
              <b>CONNECTED.</b>
            </p>{' '}
            <p>{'> '}Island integrity: 98%</p> <p>{'> '}Welcome home.</p>
          </div>
          <div className="p1-puzzle">
            <Draggable defaultPosition={{ x: -220, y: 100 }}>
              <img src={p1} draggable={false} />
            </Draggable>
            <Draggable defaultPosition={{ x: -180, y: -308 }}>
              <img src={p2} draggable={false} />
            </Draggable>
            <Draggable defaultPosition={{ x: -74, y: 125 }}>
              <img src={p3} draggable={false} />
            </Draggable>
            <Draggable defaultPosition={{ x: 110, y: 234 }}>
              <img src={p4} draggable={false} />
            </Draggable>
            <Draggable>
              <img src={p5} draggable={false} />
            </Draggable>
            <Draggable defaultPosition={{ x: 333, y: -65 }}>
              <img src={p6} draggable={false} />
            </Draggable>
            <Draggable defaultPosition={{ x: 472, y: 290 }}>
              <img src={p7} draggable={false} />
            </Draggable>
            <Draggable defaultPosition={{ x: -40, y: -20 }}>
              <img src={p8} draggable={false} />
            </Draggable>
            <Draggable defaultPosition={{ x: 390, y: 193 }}>
              <img src={p9} draggable={false} />
            </Draggable>
          </div>
          <div className="p1-text">
            <p>
              {'> '} <b>WARNING!</b>
            </p>
            <p>
              {'> '} Because your island status is not official, your message
              may not be saved properly. Upgrade your island status to an
              official island for-
            </p>
            <p>
              {'> '} <b>DECLINE.</b>
            </p>
            <p>{'> '} Message saving…</p>
            <p>{'> '} Message saved.</p>
            <p>{'> '} Closing connection…</p>
            <p>
              {'> '} <b>OFFLINE.</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
